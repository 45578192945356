<template>
  <VcSelect
    v-model="model"
    :name="formElement.key"
    text-field="caption"
    value-field="caption"
    :items="allItems"
    :label="formElement.properties.label"
    class="w-full"
    size="md"
    autocomplete
  >
    <template #placeholder>
      <div class="flex h-[50px] items-center gap-3 px-5 py-3.5 text-base">
        <input :value="$t('pages.feedback.family.all_products')" type="hidden" :name="formElement.key" />
        {{ $t("pages.feedback.family.all_products") }}
      </div>
    </template>

    <template #selected="{ item }">
      <div class="flex h-[50px] items-center gap-3 rounded-md px-5 py-3 text-base">
        <input v-model="item.caption" type="hidden" :name="formElement.key" />
        {{ item.caption }}
      </div>
    </template>

    <template #item="{ item }">
      {{ item.caption }}
    </template>
  </VcSelect>
</template>

<script lang="ts" setup>
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import type { ElementValidationResult } from "../forms-sdk";
import type { ExtendedFormElement } from "../types";

interface IProps {
  formElement: ExtendedFormElement;
  items: string[];
  errorMessage?: ElementValidationResult;
}

const props = defineProps<IProps>();
const model = defineModel<string>();
const { t: $t } = useI18n();

const allItems = [
  { caption: $t("pages.feedback.family.all_products") },
  { caption: $t("pages.feedback.family.presentation_feedback") },
  ...[...new Set(props.items)]
    .filter((item) => item)
    .sort()
    .map((item) => ({ caption: item })),
];

onBeforeMount(() => {
  // model.value = props.formElement.properties.placeHolder;
});
</script>
