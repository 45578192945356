<template>
  <footer aria-label="Footer" class="print:hidden">
    <!-- Sustenability -->
    <Susteinability />
    <!-- Top footer -->
    <div v-if="!compact" class="bg-[color:var(--color-footer-top-bg)]">
      <div class="mx-auto grid grid-cols-1 items-start gap-y-5 px-4 pb-5 pt-8 md:grid-cols-3 md:p-8 lg:w-[994px]">
        <div
          class="grid grid-cols-1 gap-5 justify-self-center overflow-hidden text-center md:row-span-3 md:max-w-[78%] md:justify-self-start md:text-left"
        >
          <FooterSales :sales-phone="footerBlock?.salesPhone" :sales-email="footerBlock?.salesEmail" />
          <FooterSupport
            :technical-phone="footerBlock?.technicalPhone"
            :technical-email="footerBlock?.technicalEmail"
          />
        </div>

        <div
          class="grid grid-cols-1 gap-5 justify-self-center text-center md:row-span-3 md:justify-self-start md:text-left lg:-ml-14"
        >
          <FooterHours :hours-line1="footerBlock?.hoursLine1" :hours-line2="footerBlock?.hoursLine2" />

          <div class="grid gap-y-2 md:justify-start">
            <div
              v-if="footerBlock?.linkedPages?.length ?? 0 > 0"
              class="text-sm font-bold text-[color:var(--color-footer-top-text)] md:block"
            >
              {{ $t("shared.layout.footer.company") }}
            </div>
            <div
              class="flex flex-wrap justify-center gap-x-4 md:grid md:justify-start"
              :class="{ 'gap-y-1': currencyCode === 'GBP', 'gap-y-2': currencyCode !== 'GBP' }"
            >
              <template v-if="footerBlock?.linkedPages">
                <FooterLink
                  v-for="page in footerBlock.linkedPages"
                  :key="page.href"
                  :title="page.text"
                  :href="page.href"
                  :target="page.target"
                  class="md:order-none md:w-auto"
                >
                </FooterLink>
              </template>
              <template v-if="footerBlock?.additionalLink">
                <a
                  class="footer-link order-last w-full text-sm font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
                  :href="footerBlock?.additionalLink.link"
                  :class="{
                    'w-full pt-2': currencyCode === 'GBP',
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <slot>{{ footerBlock?.additionalLink.displayName }}</slot>
                </a>
              </template>
            </div>
          </div>
        </div>

        <div
          class="-order-1 grid w-full grid-cols-2 items-center gap-3 justify-self-center md:order-none md:ml-[-25px] md:flex md:w-auto lg:ml-0 lg:grid"
        >
          <CurrencySelector
            v-if="$context.availableCurrencies && $context.availableCurrencies.length > 1"
            class="h-full"
          />
          <LanguageSelector v-if="$context.availableLanguages && $context.availableLanguages.length > 1" />
        </div>

        <div
          class="my-auto grid w-full grid-cols-1 justify-self-center border-t border-t-[var(--color-neutral-a4)] pt-3 md:ml-[-25px] md:border-0 md:pt-0 lg:ml-0"
        >
          <FooterSocials :social-media-links="footerBlock?.socialMediaLinks" />
        </div>

        <div
          class="mt-auto grid grid-cols-1 items-center gap-y-2 justify-self-center text-center md:ml-[-25px] lg:ml-0"
        >
          <span class="text-xs font-medium text-[color:var(--color-footer-top-text)]">
            {{ $t("shared.layout.footer.copyright", { currentYear: new Date().getFullYear() }) }}
          </span>
          <a
            v-if="isChinaDomain() && chinaDomainIdentifier"
            :href="chinaDomainIdentifier.link"
            class="footer-link text-sm font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
            >{{ chinaDomainIdentifier.displayName }}</a
          >
        </div>
      </div>
    </div>
    <!-- Bottom footer -->
    <div class="flex justify-center bg-[color:var(--color-footer-bottom-bg)] py-[10px] print:flex-row">
      <VcImage :src="$cfg.logo_trusted_image" alt="" role="presentation" class="h-[59px]" lazy />
    </div>
  </footer>
  <div class="hidden">{{ appVersion }}</div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useCurrency } from "@/core/composables";
import { useFooterBlock } from "@/core/composables/useFooterBlock";
import { useSlugInfo } from "@/shared/common";
import { CurrencySelector, LanguageSelector } from "@/shared/layout";
import { Susteinability } from "@/shared/thorlabs/support-documents";
import pkg from "../../../../../package.json";
import FooterHours from "./_internal/footer-hours.vue";
import FooterLink from "./_internal/footer-link.vue";
import FooterSales from "./_internal/footer-sales.vue";
import FooterSocials from "./_internal/footer-socials.vue";
import FooterSupport from "./_internal/footer-support.vue";

interface IProps {
  compact?: boolean;
}

defineProps<IProps>();
const { slugInfo, hasContent, fetchContent } = useSlugInfo("Footer");
const { currentCurrency } = useCurrency();
const { footerBlock, chinaDomainIdentifier, isChinaDomain } = useFooterBlock();
const { version } = pkg;
const appVersion = computed(() => version);

watch(slugInfo, async () => {
  if (hasContent.value) {
    await fetchContent();
  }
});
const currencyCode = currentCurrency.value.code;
</script>
