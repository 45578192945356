<template>
  <div class="relative z-50 bg-[--header-bottom-bg-color]">
    <a href="#maincontent" class="skip-link">{{ $t("common.buttons.skip_to_main_content") }}</a>
    <nav
      ref="bottomHeader"
      class="relative z-[2] flex min-h-20 items-center bg-[color:var(--color-header-bottom-bg)] px-5 lg:gap-x-3 xl:gap-x-10"
      role="menu"
    >
      <!-- Logo Header -->
      <a href="/" :aria-label="$t('common.buttons.home')" role="menuitem">
        <VcImage
          :src="logo?.url"
          :aria-label="$context.storeName"
          width="150"
          height="24"
          class="mr-5 h-6 xl:mr-10"
          loading="eager"
          fallback-src="/static/thorlabs/logo.svg"
        />
      </a>

      <!-- Main Menu start -->
      <DesktopMenu :desktop-menu-items="desktopMenuItems" role="menuitem" />

      <!-- Main Menu end -->
      <div class="ml-auto flex gap-x-8 py-3 xl:py-4">
        <SearchBar role="menuitem" />
        <div class="-mx-2 flex items-center">
          <template v-if="isAuthenticated">
            <div class="group relative">
              <button
                ref="showAccountButton"
                type="button"
                class="h-12 items-center px-3 group-hover:[&_svg]:text-primary group-focus:[&_svg]:text-primary"
                :aria-label="$t('common.links.account')"
                role="menuitem"
                @click="toggleAccount"
              >
                <VcIcon
                  name="user-circle"
                  class="size-6"
                  :class="accountOpen ? 'text-[color:var(--color-primary-a1)]' : 'text-[color:var(--color-neutral-a1)]'"
                />
              </button>
              <div
                v-show="accountOpen"
                ref="accountOpenElement"
                class="absolute right-0 top-[calc(100%+17px)] w-[260px] transition-all"
              >
                <!-- class="invisible absolute right-0 top-full w-80 opacity-0 transition-all group-hover:visible group-hover:top-[calc(100%+31px)] group-hover:opacity-100" -->
                <div
                  class="border border-b-[3px] border-[color:var(--color-neutral-a4)] border-b-[color:var(--color-primary-a1)] bg-white p-5"
                >
                  <div class="flex flex-col gap-2 text-base">
                    <span class="mt-2 text-[--color-neutral-a2]"> {{ $t(`${greeting}`) }}, {{ greetingName }}! </span>
                    <div class="my-4 h-px w-full bg-[color:var(--color-neutral-a4)]"></div>
                    <a href="/account" class="mb-4 text-base hover:font-semibold">{{
                      $t("pages.cart.my_account_title")
                    }}</a>
                    <a href="/account/orders" class="mb-4 text-base hover:font-semibold">{{
                      $t("shared.account.navigation.links.my_orders")
                    }}</a>
                    <a href="/account/quotes" class="text-base hover:font-semibold">{{
                      $t("shared.account.navigation.links.my_quotes")
                    }}</a>
                    <div class="my-4 h-px w-full bg-[color:var(--color-neutral-a4)]"></div>
                    <button
                      class="text-left text-base capitalize hover:font-semibold"
                      type="button"
                      @click="() => signMeOut()"
                    >
                      {{ $t("shared.layout.header.link_logout") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="group relative">
              <button
                ref="showAccountButton"
                type="button"
                class="h-12 items-center px-3 group-hover:[&_svg]:text-primary group-focus:[&_svg]:text-primary"
                :aria-label="$t('common.links.account')"
                role="menuitem"
                @click="toggleAccount"
              >
                <VcIcon
                  name="user"
                  class="size-5 text-[color:var(--color-neutral-a1)]"
                  :class="{ 'text-primary': accountOpen }"
                />
              </button>
              <div
                v-show="accountOpen"
                ref="accountOpenElement"
                class="absolute right-0 top-[calc(100%+17px)] w-[360px] transition-all"
              >
                <div
                  class="border border-b-[3px] border-[color:var(--color-neutral-a4)] border-b-[color:var(--color-primary-a1)] bg-white p-8"
                >
                  <SignInForm @close-form="accountOpen = false" />
                </div>
              </div>
            </div>
          </template>
          <template v-for="item in desktopMainMenuItems" :key="item.id">
            <div v-if="item.id === 'cart'" class="mini-cart">
              <BottomHeaderLink
                v-if="item.id === 'cart'"
                :link="item"
                :count="cart ? sumBy(cart?.items, (x) => x.quantity) : 0"
              />
            </div>
            <template v-else-if="item.id === 'push-messages'">
              <PushMessages v-if="$cfg.push_messages_enabled && isAuthenticated" :y-offset="36">
                <template #trigger="{ totalCount, unreadCount }">
                  <BottomHeaderLink :link="item" :count="unreadCount">
                    <template #icon>
                      <transition :name="unreadCount ? 'shake' : ''" mode="out-in">
                        <svg
                          v-if="item.icon"
                          :key="totalCount"
                          height="24"
                          width="24"
                          class="mb-0.5 text-[color:var(--color-primary)]"
                        >
                          <use :href="item.icon" />
                        </svg>
                      </transition>
                    </template>
                    {{ item.title }}
                  </BottomHeaderLink>
                </template>
              </PushMessages>
            </template>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, syncRefs, useScrollLock } from "@vueuse/core";
import { sumBy } from "lodash";
import { defineProps, ref, shallowRef, onUnmounted, onMounted } from "vue";
import { useNavigations } from "@/core/composables";
import { SignInForm, useSignMeOut } from "@/shared/account";
import { useUser } from "@/shared/account/composables/useUser";
import { useShortCart } from "@/shared/cart";
import { SearchBar } from "@/shared/layout";
import BottomHeaderLink from "./bottom-header-link.vue";
import DesktopMenu from "./desktop-menu.vue";
import type { IDesktopMenuItem, ILogo } from "@/shared/thorlabs/blocks";
import PushMessages from "@/shared/push-messages/components/push-messages.vue";

interface IProps {
  logo?: ILogo;
  desktopMenuItems?: IDesktopMenuItem[];
}

defineProps<IProps>();

const { isAuthenticated, greeting, greetingName } = useUser();
const { cart } = useShortCart();
const { desktopMainMenuItems } = useNavigations();
const { signMeOut } = useSignMeOut();

const bottomHeader = ref<HTMLElement | null>(null);
const catalogMenuElement = shallowRef<HTMLElement | null>(null);
const showAccountButton = shallowRef<HTMLElement | null>(null);
const showProductsMenuButton = shallowRef<HTMLElement | null>(null);
const catalogMenuVisible = ref(false);

const open = ref(false);
const accountOpen = ref(false);
const accountOpenElement = ref(null);

onClickOutside(
  accountOpenElement,
  () => {
    accountOpen.value = false;
  },
  { ignore: [showAccountButton] },
);

const toggleAccount = () => {
  accountOpen.value = !accountOpen.value;
  open.value = false;
};

onClickOutside(
  catalogMenuElement,
  () => {
    catalogMenuVisible.value = false;
  },
  { ignore: [showProductsMenuButton] },
);

onMounted(() => {
  window.addEventListener("keydown", handleEscKey);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscKey);
});
function handleEscKey(event: KeyboardEvent) {
  if (event.key === "Escape") {
    accountOpen.value = false;
  }
}
syncRefs(catalogMenuVisible, useScrollLock(document.body));
</script>

<style scoped lang="scss">
::v-deep {
  .vc-button {
    @apply min-h-[48px];
  }
}
</style>
