import { syncRefs, useAsyncState, useLocalStorage } from "@vueuse/core";
import { ref, toValue } from "vue";
import { useRouter } from "vue-router";
import { updateContact, useGetMeQuery, useMergeCartMutation } from "@/core/api/graphql";
import { useAuth } from "@/core/composables/useAuth";
import { useCurrency } from "@/core/composables/useCurrency";
import { useLanguages } from "@/core/composables/useLanguages";
import { USER_ID_LOCAL_STORAGE } from "@/core/constants/local-storage-keys";
import { TabsType, openReturnUrl, useBroadcast } from "@/shared/broadcast";
import { useFullCart, useShortCart } from "@/shared/cart/composables";
import { useWishlists } from "@/shared/wishlists";
import type {
  ContactTypeExtension,
  IdentityErrorType,
  InputUpdateContactTypeExtension,
} from "@/core/api/graphql/types";
import type { SignMeIn } from "@/shared/account/types";
import type { MaybeRefOrGetter } from "vue";

export function useSignMeIn(payload: MaybeRefOrGetter<SignMeIn>) {
  const { errors: authErrors, authorize } = useAuth();
  const broadcast = useBroadcast();
  const { cart } = useShortCart();
  const { saveCartOnActionURL } = useFullCart();
  const { moveFromItemsForLaterToCart } = useWishlists();
  const { mutate: mergeCart } = useMergeCartMutation();
  const { supportedLanguages, saveLocale, currentLanguage } = useLanguages();
  const { supportedCurrencies, saveCurrencyCode, currentCurrency } = useCurrency();
  const { result: me, load: getMe } = useGetMeQuery();
  const savedLocale = useLocalStorage<string>("locale", "");
  const savedCurrencyCode = useLocalStorage<string | null>("currency", "");
  const router = useRouter();

  const { isLoading: loading, execute: signIn } = useAsyncState(
    async () => {
      const { email, password } = toValue(payload);
      await authorize(email, password, currentLanguage.value.cultureName, currentCurrency.value.code);
      localStorage.removeItem(USER_ID_LOCAL_STORAGE);

      // get user that will be applied after reload.
      await getMe();

      if (me.value?.me) {
        const meCopy = me.value.me;

        if (router.currentRoute.value.query.action === "save-cart") {
          await saveCartOnActionURL(meCopy.id, cart.value?.id);
        }

        if (router.currentRoute.value.query.action === "save-items") {
          const item = router.currentRoute.value.query.optionalItem
            ? router.currentRoute.value.query.optionalItem.toString()
            : "";
          await moveFromItemsForLaterToCart([item], me.value?.me?.id, cart.value?.id);
        }

        if (cart.value?.id) {
          await mergeCart({ command: { userId: meCopy.id, secondCartId: cart.value.id } });
        }

        let isUpdateContactLanguageNeeded = false;
        let isUpdateContactCurrencyNeeded = false;

        if (meCopy.contact?.defaultLanguage) {
          const contactLanguage = supportedLanguages.value.find(
            (item) => item.cultureName === meCopy.contact!.defaultLanguage,
          );

          if (contactLanguage) {
            saveLocale(contactLanguage.twoLetterLanguageName, false);
          }
        } else if (savedLocale.value) {
          isUpdateContactLanguageNeeded = true;
        }

        if (meCopy.contact!.currencyCode) {
          const contactCurrency = supportedCurrencies.value.find((item) => item.code === meCopy.contact!.currencyCode);

          if (contactCurrency) {
            saveCurrencyCode(contactCurrency.code, false);
          }
        } else if (savedCurrencyCode.value) {
          isUpdateContactCurrencyNeeded = true;
        }

        if (isUpdateContactLanguageNeeded || isUpdateContactCurrencyNeeded) {
          await updateLanguageAndCurrency(
            isUpdateContactCurrencyNeeded,
            isUpdateContactLanguageNeeded,
            meCopy.contact as ContactTypeExtension,
          );
        }
      }

      broadcast.emit(openReturnUrl, undefined, TabsType.ALL);
    },
    null,
    { immediate: false },
  );

  async function updateLanguageAndCurrency(
    isUpdateContactCurrencyNeeded: boolean,
    isUpdateContactLanguageNeeded: boolean,
    contact: ContactTypeExtension,
  ) {
    const contactData: InputUpdateContactTypeExtension = {
      defaultLanguage: isUpdateContactLanguageNeeded
        ? supportedLanguages.value.find((lang) => lang.twoLetterLanguageName === savedLocale.value)?.cultureName
        : contact?.defaultLanguage,
      currencyCode: isUpdateContactCurrencyNeeded
        ? supportedCurrencies.value.find((currency) => currency.code === savedCurrencyCode.value)?.code
        : contact?.currencyCode,
      firstName: contact?.firstName ?? "",
      lastName: contact?.lastName ?? "",
      id: contact?.id ?? "",
      isOverride: false,
    };

    await updateContact(contactData);
  }
  const errors = ref<IdentityErrorType[]>();

  syncRefs(authErrors, errors);

  function resetErrors() {
    errors.value = [];
  }

  return {
    errors,
    loading,
    signIn,
    resetErrors,
    authorize,
  };
}
