export enum FormElementsContentTypes {
  AutoCompleteHiddenElementBlock = "AutoCompleteHiddenElementBlock",
  ChoiceElementBlock = "ChoiceElementBlock",
  DateTimeElementBlock = "DateTimeElementBlock",
  HcaptchaElementBlock = "HcaptchaElementBlock",
  HcaptchaValidElementBlock = "HcaptchaValidElementBlock",
  ItemsSelectionElementBlock = "ItemsSelectionElementBlock",
  NumberElementBlock = "NumberElementBlock",
  SelectionElementBlock = "SelectionElementBlock",
  TextareaElementBlock = "TextareaElementBlock",
  TextboxElementBlock = "TextboxElementBlock",
}

export enum LabelPlaceholders {
  ItemNumber = "ItemNumber",
}

export enum TextAutoCompleteTypes {
  Email = "email",
  Name = "name",
}

export enum HiddenAutoCompleteTypes {
  CountryCode = 100,
  Currency,
  Email,
  ItemNumber,
  Url,
  Name,
  FamilyPage,
}
