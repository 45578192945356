import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import mutationDocument from "./moveWishlistItemMutation.graphql";
import type {
  CartWishlistTupleType,
  InputMoveWishlistItemTypeExtension,
  Mutations,
  MutationsMoveWishlistItemsTlArgs,
} from "@/core/api/graphql/types";

export async function moveWishlistItem(payload: InputMoveWishlistItemTypeExtension): Promise<CartWishlistTupleType> {
  const { storeId, cultureName, currencyCode } = globals;
  payload.storeId = storeId;
  payload.cultureName = cultureName;
  payload.currencyCode = currencyCode;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "moveWishlistItemsTL">>,
    MutationsMoveWishlistItemsTlArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });
  return data!.moveWishlistItemsTL;
}
