<template>
  <div class="FormContainerBlock grid gap-y-5 font-medium">
    <form :id="form?.key" :key="formKey" class="grid gap-y-5">
      <template v-for="element in form?.formElements" :key="element.key">
        <template v-if="shouldShow(element)">
          <FormText
            v-if="
              element.contentType === FormElementsContentTypes.TextboxElementBlock ||
              element.contentType === FormElementsContentTypes.NumberElementBlock
            "
            :model="inputModels[element.key]"
            :form-element="element"
            :product="product"
            :error-message="inputErrors[element.key]"
            @update:error-message="updateInputError(element.key, $event)"
          />
          <FormTextArea
            v-if="element.contentType === FormElementsContentTypes.TextareaElementBlock"
            :model="inputModels[element.key]"
            :form-element="element"
            :error-message="inputErrors[element.key]"
            @update:error-message="updateInputError(element.key, $event)"
          />
          <FormCheckbox
            v-if="
              element.contentType === FormElementsContentTypes.ChoiceElementBlock && element.properties.allowMultiSelect
            "
            v-model="inputModels[element.key]"
            :form-element="element"
          />
          <FormRadio
            v-if="
              element.contentType === FormElementsContentTypes.ChoiceElementBlock &&
              !element.properties.allowMultiSelect
            "
            v-model="inputModels[element.key]"
            :form-element="element"
          />
          <FormDateTime
            v-if="element.contentType === FormElementsContentTypes.DateTimeElementBlock"
            :model="inputModels[element.key]"
            :form-element="element"
            :error-message="inputErrors[element.key]"
            @update:error-message="updateInputError(element.key, $event)"
          />
          <FormSelection
            v-if="element.contentType === FormElementsContentTypes.SelectionElementBlock"
            :model="inputModels[element.key]"
            :form-element="element"
            :error-message="inputErrors[element.key]"
            @update:error-message="updateInputError(element.key, $event)"
          />
          <FormItemsSelection
            v-if="element.contentType === FormElementsContentTypes.ItemsSelectionElementBlock"
            :form-element="element"
            :items="items || []"
          />
          <FormAutocomplete
            v-if="element.contentType === FormElementsContentTypes.AutoCompleteHiddenElementBlock"
            :form-element="element"
            :product="product"
            :family-page-guid="familyPageGuid"
          />
          <FormCaptcha
            v-if="
              element.contentType === FormElementsContentTypes.HcaptchaElementBlock ||
              element.contentType === FormElementsContentTypes.HcaptchaValidElementBlock
            "
            ref="formCaptchaRefs"
            :element-key="element.key"
            :site-key="element.properties.siteKey"
          />
        </template>
      </template>
    </form>
    <div v-if="anyRequiredFields" class="text-sm capitalize text-[--color-neutral-a2]">
      * {{ $t("common.messages.required_fields") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useClientAuth } from "@/core/composables/useClientAuth";
import { globals } from "@/core/globals";
import { FormElementsContentTypes } from "../enums";
import { FormSubmitter } from "../forms-sdk";
import FormAutocomplete from "./form-autocomplete.vue";
import FormCaptcha from "./form-captcha.vue";
import FormCheckbox from "./form-checkbox.vue";
import FormDateTime from "./form-date-time.vue";
import FormItemsSelection from "./form-items-selection.vue";
import FormRadio from "./form-radio.vue";
import FormSelection from "./form-selection.vue";
import FormTextArea from "./form-text-area.vue";
import FormText from "./form-text.vue";
import type {
  ElementValidationResult,
  FormContainer,
  FormSubmission,
  FormSubmitModel,
  FormValidationResult,
} from "../forms-sdk";
import type { ExtendedFormContainer, ExtendedFormElement } from "../types";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  form?: ExtendedFormContainer;
  product?: Product;
  familyPageGuid?: string;
  items?: string[];
}

const emit = defineEmits<IEmits>();
const props = defineProps<IProps>();

interface IEmits {
  (event: "submitted"): void;
}

// used to re-render the form after submit
const formKey = ref(0);

const inputModels = ref<Record<string, string>>({});
const inputErrors = ref<Record<string, ElementValidationResult>>({});

function updateInputError(key: string, error: ElementValidationResult) {
  inputErrors.value[key] = error;
}

const formCaptchaRefs = ref<InstanceType<typeof FormCaptcha>[] | null>(null);
const formValidationResult = ref<FormValidationResult[]>([]);

function shouldShow(element: ExtendedFormElement) {
  if (element.properties.satisfiedAction === "hide" && element.properties.conditions.length > 0) {
    const condition = element.properties.conditions[0];
    const modelValue = inputModels.value[condition.field];
    if (condition.operator === "Equals" && condition.fieldValue === modelValue) {
      return false;
    }
  }
  return true;
}

const anyRequiredFields = computed(
  () =>
    !!props.form?.formElements
      .map((e) => e as ExtendedFormElement)
      .find((e) => e.properties.validators.find((v) => v.type === "RequiredValidator")),
);

function resetForm(): void {
  if (!props.form) {
    return;
  }
  const formElement = document.getElementById(props.form.key) as HTMLFormElement;
  formElement.reset();
  formKey.value++;
}

async function submit() {
  if (!props.form) {
    return;
  }

  const formElement = document.getElementById(props.form.key) as HTMLFormElement;
  const formData = new FormData(formElement);
  const values: FormSubmission[] = [];

  // Convert FormData entries to an object
  formData.forEach((value, key) => {
    values.push({ elementKey: key, value: value as string });
  });

  const formSubmitter = new FormSubmitter(props.form as FormContainer, globals.optimizelyHost + "/");

  formValidationResult.value = formSubmitter.doValidate(values);
  if (formValidationResult.value.find((e) => e.result.message)) {
    formValidationResult.value.forEach((result) => {
      inputErrors.value[result.elementKey] = result.result;
    });
    return;
  }

  const accessToken = await getAccessToken();

  const formSubmitModel: FormSubmitModel = {
    formKey: props.form.key,
    locale: props.form.locale,
    isFinalized: true,
    partialSubmissionKey: "",
    hostedPageUrl: window.location.href,
    submissionData: values,
    accessToken: accessToken,
    currentStepIndex: 0,
  };

  //logic to handle result
  formSubmitter
    .doSubmit(formSubmitModel)
    .then(() => {
      emit("submitted");
      if (formCaptchaRefs.value?.length) {
        formCaptchaRefs.value[0].reset();
      }
      formElement.reset();
      formKey.value++;
    })
    .catch(() => {});
}

async function getAccessToken(): Promise<string> {
  const { authorize } = useClientAuth();
  await authorize();
  const clientAuth = localStorage.getItem("clientAuth");
  if (clientAuth) {
    const clientAuthObject = JSON.parse(clientAuth);
    if (clientAuthObject.access_token) {
      return clientAuthObject.access_token;
    }
  }
  return "";
}

defineExpose({
  resetForm,
  submit,
});
</script>
