import { computed, readonly, ref, shallowRef } from "vue";
import {
  addWishlist,
  addWishlistBulkItem,
  addWishlistItem,
  addWishlistItems,
  moveWishlistItem,
  changeWishlist,
  deleteWishlist,
  deleteWishlistItem,
  getWishList,
  getWishlists,
  updateWishlistItems,
  getWishListByName,
} from "@/core/api/graphql/account";
import { getProductNotification } from "@/core/api/graphql/account/queries/getProductNotification";
import { useCurrency } from "@/core/composables";
import { SortDirection } from "@/core/enums";
import { globals } from "@/core/globals";
import { Logger, asyncForEach } from "@/core/utilities";
import type {
  CartExtension,
  InputAddWishlistBulkItemType,
  InputAddWishlistItemsType,
  InputAddWishlistItemType,
  InputMoveWishlistItemTypeExtension,
  InputRemoveWishlistItemType,
  InputUpdateWishlistItemsType,
  ProductNotificationType,
  WishlistType,
} from "@/core/api/graphql/types";
import type { ChangeWishlistPayloadType, CreateWishlistPayloadType } from "@/core/types";
import type { Ref } from "vue";

const loading = ref(true);
const lists = shallowRef<WishlistType[]>([]);
const list: Ref<WishlistType | undefined> = ref();
const listLoading = ref(true);
const { currentCurrency } = useCurrency();

export function useWishlists(options: { autoRefetch: boolean } = { autoRefetch: true }) {
  async function createWishlist(payload: CreateWishlistPayloadType): Promise<string | undefined> {
    let newList: WishlistType;
    loading.value = true;

    try {
      newList = await addWishlist(payload);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${createWishlist.name}`, e);
      throw e;
    }

    if (options.autoRefetch) {
      await fetchWishlists();
    }

    return newList.id;
  }

  async function updateWishlist(payload: ChangeWishlistPayloadType): Promise<void> {
    listLoading.value = true;

    try {
      list.value = await changeWishlist(payload);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${updateWishlist.name}`, e);
      throw e;
    } finally {
      listLoading.value = false;
    }

    if (options.autoRefetch) {
      await fetchWishlists();
    }
  }

  async function fetchWishlists(): Promise<void> {
    loading.value = true;

    try {
      const searchParams = { itemsPerPage: 9999, sort: `name:${SortDirection.Ascending}` };

      lists.value = (await getWishlists(searchParams)).items || [];
    } catch (e) {
      Logger.error(`${useWishlists.name}.${fetchWishlists.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchWishList(listId: string) {
    listLoading.value = true;
    try {
      list.value = await getWishList(listId);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${fetchWishList.name}`, e);
      throw e;
    } finally {
      listLoading.value = false;
    }
  }

  async function fetchWishListByName(name: string, customerId: string) {
    listLoading.value = true;
    try {
      list.value = await getWishListByName(name, customerId);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${fetchWishList.name}`, e);
      throw e;
    } finally {
      listLoading.value = false;
    }
  }

  async function removeWishlist(listId: string): Promise<boolean> {
    let result = false;

    loading.value = true;

    try {
      result = await deleteWishlist(listId);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${removeWishlist.name}`, e);
      throw e;
    }

    if (options.autoRefetch) {
      await fetchWishlists();
    }

    return result;
  }

  async function addItemsToWishlists(payloads: InputAddWishlistBulkItemType) {
    loading.value = true;

    try {
      await addWishlistBulkItem(payloads);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${addItemsToWishlists.name}`, e);
      throw e;
    }

    loading.value = false;
  }

  async function addItemsToWishlist(payloads: InputAddWishlistItemsType) {
    loading.value = true;

    try {
      await addWishlistItems(payloads);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${addItemsToWishlist.name}`, e);
      throw e;
    }

    loading.value = false;
  }

  async function removeItemsFromWishlists(payload: InputRemoveWishlistItemType[]) {
    loading.value = true;

    // TODO: Use single query
    await asyncForEach(payload, async (item) => {
      try {
        await deleteWishlistItem(item);
      } catch (e) {
        Logger.error(`${useWishlists.name}.${removeItemsFromWishlists.name}`, e);
        throw e;
      }
    });

    loading.value = false;
  }

  async function updateItemsInWishlist(payload: InputUpdateWishlistItemsType): Promise<void> {
    loading.value = true;

    try {
      await updateWishlistItems(payload);
      await fetchWishList(payload.listId);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${updateItemsInWishlist.name}`, e);
    } finally {
      loading.value = false;
    }
  }

  async function addItemToWishlist(payload: InputAddWishlistItemType): Promise<void> {
    loading.value = true;

    try {
      await addWishlistItem(payload);
    } catch (e) {
      Logger.error(`${useWishlists.name}.${addWishlistItem.name}`, e);
    } finally {
      loading.value = false;
    }
  }

  async function moveItemToWishlist(payload: InputMoveWishlistItemTypeExtension): Promise<CartExtension | undefined> {
    loading.value = true;

    try {
      const result = await moveWishlistItem(payload);
      list.value = result.wishlist;
      return result.cart;
    } catch (e) {
      Logger.error(`${useWishlists.name}.${moveWishlistItem.name}`, e);
      return undefined;
    } finally {
      loading.value = false;
    }
    return undefined;
  }

  async function getProductsByCodes(codes: string[]) {
    loading.value = true;
    let result: ProductNotificationType[];

    try {
      result = await getProductNotification(codes);
      return result;
    } catch (e) {
      Logger.error(`${useWishlists.name}.${getProductNotification.name}`, e);
    } finally {
      loading.value = false;
    }
  }

  async function moveFromItemsForLaterToCart(
    items: string[],
    userId: string | undefined,
    cartId?: string,
  ): Promise<void> {
    if (items.length === 0) {
      return;
    }

    const itemsForLaterPayload = <InputMoveWishlistItemTypeExtension>{
      lineItemIds: items,
      listId: cartId,
      cultureName: globals.cultureName,
      currencyCode: currentCurrency.value.code,
      storeId: globals.storeId,
      destinationListId: "",
      userId,
    };

    await moveItemToWishlist(itemsForLaterPayload);
  }

  return {
    fetchWishlists,
    fetchWishList,
    fetchWishListByName,
    createWishlist,
    removeWishlist,
    addItemsToWishlists,
    moveItemToWishlist,
    updateWishlist,
    updateItemsInWishlist,
    removeItemsFromWishlists,
    addItemToWishlist,
    getProductsByCodes,
    addItemsToWishlist,
    moveFromItemsForLaterToCart,
    loading: readonly(loading),
    lists: computed(() => lists.value),
    list: computed(() => list.value),
    listLoading: readonly(listLoading),
  };
}
