<template>
  <div
    class="pricing-box flex flex-wrap gap-x-[10px] gap-y-1 font-medium"
    :class="{ 'leading-none': showUserDiscount }"
  >
    <div v-if="showUserDiscount" class="pricing-box__with-tax text-[--color-neutral-a2] line-through">
      {{ formatPriceForCurrency(listWithTax, currentCurrency) }}
    </div>

    <div class="with-discount flex flex-wrap gap-x-2 gap-y-1">
      <VcPriceDisplay
        v-if="shouldUseActualPrice(value?.list, value?.actual)"
        class="font-bold"
        :class="priceColorClass"
        :formatted-value="formatPriceForCurrency(useCnyExtraTaxPrice ? actualWithTax : value?.actual, currentCurrency)"
      />
      <VcPriceDisplay
        v-else
        class="font-bold"
        :formatted-value="formatPriceForCurrency(useCnyExtraTaxPrice ? listWithTax : value?.list, currentCurrency)"
      />

      <div v-if="showUserDiscount" class="pricing-box__discount ml-[-2px]">
        ({{ userDiscountPercentage }}% Discount)
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useCurrency } from "@/core/composables";
import { formatPriceForCurrency } from "@/shared/cart";
import { shouldUseActualPrice } from "@/ui-kit/utilities/price";
import type { MoneyType, PriceType } from "@/core/api/graphql/types";

interface IProps {
  value?: PriceType | { list: MoneyType; actual: MoneyType };
  priceColorClass?: string;
  userDiscountPercent?: number | undefined;
  listWithTax?: MoneyType;
  actualWithTax?: MoneyType;
}
const props = withDefaults(defineProps<IProps>(), {
  priceColorClass: "",
});

const { currentCurrency } = useCurrency();

const useCnyExtraTaxPrice = computed(
  () =>
    currentCurrency.value.code === "CNY" &&
    !!props.listWithTax &&
    !!props.value &&
    props.listWithTax.amount > props.value.actual.amount,
);
const showUserDiscount = computed(() => props.userDiscountPercent && props.userDiscountPercent > 0);
const userDiscountPercentage = computed(() => {
  const overUnitDiscount = props.userDiscountPercent ? props.userDiscountPercent * 100 : 0;
  return Math.round(overUnitDiscount * 100) / 100;
});
</script>
